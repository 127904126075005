<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h1 class="titlesection">Ops, pagina non trovata...</h1>
        <h4 class="text-white">Errore 404</h4>
        <p class="text">
          La pagina cercata non esiste più o è stata spostata.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 mt-5">
        <router-link to="/" class="btn btn-dark btn-lg">Torna alla home</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "NotFound",
  mounted() {

  }
}
</script>

<style scoped>

</style>