<template>
  <div class="container">

  </div>
</template>

<script>

export default {
  name: "DistintaBase",
  components: {
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>