<template>
  <section class="wrappage">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h1 class="title-section" data-aos="fade-left">CONTACTS</h1>
          <p class="text-paragraph">
            Request an advice from one of our departments by following the form below to contact us.<br>
            We will be happy to give you the informations you need.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-white">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h1 class="title-section mb-5" data-aos="fade-left">OUR TEAM</h1>
          <div class="row">
            <div class="col-lg-4 mb-5">
              <h5 class="titledescription fw-bolder">Riccardo Cicerone</h5>
              <small>Business developer</small>
              <p class="text">
                riccardo@saturnoconsulting.com<br>
                +39 366 1470797
              </p>
            </div>
            <div class="col-lg-4 mb-5">
              <h5 class="titledescription fw-bolder">Renzo Cappelli</h5>
              <small>Chief Technical Officer</small>
              <p class="text">
                renzo@saturnoconsulting.com<br>
                +39 351 8924196
              </p>
            </div>
            <div class="col-lg-4 mb-5">
              <h5 class="titledescription fw-bolder">Angelo Priori</h5>
              <small>Chemical Engineering</small>
              <p class="text">
                angelo@saturnoconsulting.com<br>
                +39 335 7617803
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 mb-5">
              <h5 class="titledescription fw-bolder">Antonello Dulcamara</h5>
              <small>Manager</small>
              <p class="text">
                antonello@saturnoconsulting.com<br>
                +39 347 8313815
              </p>
            </div>
            <div class="col-lg-4 mb-5">
              <h5 class="titledescription fw-bolder">Annamaria Colagrande</h5>
              <small>Web Developer</small>
              <p class="text">
                annamria@saturnoconsulting.com<br>
                +39 351 8924196
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <h6 class="secondtitle mb-3 fw-bolder">CORPORATE</h6>
          <p class="text">
            <b>Saturno Consulting Srl</b>
            <br>
            Via Antica Arischia 209/B<br>
            67100 L'Aquila (AQ)<br>
            P.IVA 02009750668<br>
            Codice SDI: M5UXCR1<br>
            PEC: sturnoconsulting@kelipec.it
          </p>
        </div>
        <div class="col-lg-6">
          <h6 class="secondtitle mb-3 fw-bolder">OPERATIONAL HEADQUARTER</h6>
          <p class="text">
            <b>Saturno Consulting Srl</b><br>
            Via Cascina, 6<br>
            67100 L’Aquila (AQ)
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-black">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <h4 class="text-grey mb-4">Send us a message</h4>
          <TextScroller :class="'casa1'" :text="'Now all you have to do is to write us and tell about your project'"/>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-white">
    <div class="container">
      <FormContact/>
    </div>
  </section>
</template>
<script>
import FormContact from "../components/FormContact";
import TextScroller from "@/components/TextScroller.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Contact',
  data() {
    return {}
  },
  components: {TextScroller, FormContact},
  mounted() {

  }
}
</script>

<style scoped>

</style>