<template>
  <div class="fl-block sticky" id="particles" :style="{height: height + 'px'}">
    <div class="conttext">
      <div class="textabsolute">
        Omnichannel<br>solutions<br>expert
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import 'particles.js'
export default {
  name: "BoxParticles",
  props: {
    height: {
      type: Number,
      required: true
    }
  },
  data () {
    return {}
  },
  mounted() {
    this.initParticles();
  },
  methods : {
    initParticles(){
      window.particlesJS('particles', {
        "particles": {
          "number": {
            "value": 200,
            "density": {
              "enable": true,
              "value_area": 800
            }
          },
          "color": {
            "value": "#ffffff"
          },
          "shape": {
            "type": "circle",
            "stroke": {
              "width": 0,
              "color": "#000000"
            },
            "polygon": {
              "nb_sides": 5
            },
            "image": {
              "src": "img/github.svg",
              "width": 100,
              "height": 100
            }
          },
          "opacity": {
            "value": 1,
            "random": false,
            "anim": {
              "enable": false,
              "speed": 1,
              "opacity_min": 0.1,
              "sync": false
            }
          },
          "size": {
            "value": 3,
            "random": true,
            "anim": {
              "enable": false,
              "speed": 40,
              "size_min": 0.1,
              "sync": false
            }
          },
          "line_linked": {
            "enable": true,
            "distance": 150,
            "color": "#ffffff",
            "opacity": 0.4,
            "width": 1
          },
          "move": {
            "enable": true,
            "speed": 6,
            "direction": "none",
            "random": false,
            "straight": false,
            "out_mode": "out",
            "bounce": false,
            "attract": {
              "enable": false,
              "rotateX": 600,
              "rotateY": 1200
            }
          }
        },
        "interactivity": {
          "detect_on": "canvas",
          "events": {
            "onhover": {
              "enable": true,
              "mode": "repulse"
            },
            "onclick": {
              "enable": false,
              "mode": "push"
            },
            "resize": true
          },
          "modes": {
            "grab": {
              "distance": 400,
              "line_linked": {
                "opacity": 1
              }
            },
            "bubble": {
              "distance": 400,
              "size": 40,
              "duration": 2,
              "opacity": 8,
              "speed": 3
            },
            "repulse": {
              "distance": 100,
              "duration": 0.4
            },
            "push": {
              "particles_nb": 4
            },
            "remove": {
              "particles_nb": 2
            }
          }
        },
        "retina_detect": true
      });
    }
  }
}
</script>
<style scoped>

.conttext{
  position: relative;
  width: 100%;
  background-color: transparent;
  z-index: 9999;
}

.textabsolute{
  background-color: transparent !important;
  font-size: 45px;
  text-align: center;
  position: absolute;
  color: #fff;
  width: 100%;
  height: 100%;
  margin-top: 40%;
  z-index: 0;
}

@media screen and (max-width: 992px) {
  .fl-block {
    height: 300px !important;
  }
}
</style>