<template>
  <div class="container">

  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Connettore",
  components: {

  },
  mounted() {

  }
}
</script>

<style scoped>

</style>