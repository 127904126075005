<template>
  <section class="section-home">
    <video src="/video/trailer.mp4" muted="" autoplay="" loop="" playsinline></video>
    <div class="layer">
      <div class="leading-home">
        <h1 class="mb-3" data-aos="fade-left" data-aos-anchor-placement="center-bottom">
          Generative AI applied<br>to e-commerce and retail.
        </h1>
        <button class="btn btn-lg btn-outline-light">Discover more</button>
      </div>
    </div>
  </section>
  <section class="wrappersticky">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4">
          <BoxParticles :height="700"/>
        </div>
        <div class="col-lg-8">
          <div class="card cardhome" style="background-image: url('img/_IUL2800.jpg'); background-size: cover;">
            <div class="layer"></div>
          </div>
          <div class="card cardhome" style="background-image: url('img/MDL_3271.png'); background-size: cover;">
            <div class="layer"></div>
            <!--<h1 data-aos="fade-left">Realizziamo software innovativo per il retail</h1>
            <p>
              Progettiamo software in cloud all'avanguardia per il settore retail, puntando all'innovazione e alla trasformazione digitale.
              Le nostre soluzioni sono progettate per ottimizzare i processi di vendita, migliorare l'esperienza cliente
              e incrementare l'efficienza operativa, guidando i nostri clienti verso il futuro del commercio al dettaglio.
            </p>-->
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-black">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <h4 class="text-grey mb-4">Who We Are</h4>
          <TextScroller :class="'home1'" :text="'Saturno Consulting is at the forefront of digital innovation in retail, enhancing strategic collaborations through events and knowledge sharing. Our extensive industry experience allows us to lead brands through digital transformation with advanced solutions, promoting collective growth and setting new e-commerce standards.'"/>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper swiperwrapper bg-white">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h4 class="text-grey mb-4">Our Technologies</h4>
        </div>
        <div class="col-lg-12">
          <swiper
              :slides-per-view="5"
              :space-between="50"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
              :autoplay="autoplay"
              :effect="'swiper'"
              :loop="true"
              :breakpoints="breakpoints"
              :modules="modules"
          >
            <swiper-slide>
              <img class="img-fluid img-swiper" src="/img/tech/1-vue.jpg">
            </swiper-slide>
            <swiper-slide>
              <img class="img-fluid img-swiper" src="/img/tech/2-laravel.jpg">
            </swiper-slide>
            <swiper-slide>
              <img class="img-fluid img-swiper" src="/img/tech/3-react.jpg">
            </swiper-slide>
            <swiper-slide>
              <img class="img-fluid img-swiper" src="/img/tech/4-aws.jpg">
            </swiper-slide>
            <swiper-slide>
              <img class="img-fluid img-swiper" src="/img/tech/5-ovh.jpg">
            </swiper-slide>
            <swiper-slide>
              <img class="img-fluid img-swiper" src="/img/tech/6-angular.jpg">
            </swiper-slide>
            <swiper-slide>
              <img class="img-fluid img-swiper" src="/img/tech/2-laravel.jpg">
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </section>
  <!--<section class="wrapper swiperwrapper bg-white">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mb-5">
          <h4 class="text-grey mb-4">I nostri prodotti</h4>
          <h1 class="title-section" data-aos="fade-right">Strumenti<br>e tecnologie AI<br>per potenziare le aziende</h1>
          <div class="mt-4">
            <button class="btn btn-lg btn-slider swiper-button-prev">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
              </svg>
            </button>
            <button class="btn btn-lg btn-slider swiper-button-next">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="col-lg-6 mb-5">
          <swiper
              :slides-per-view="1"
              :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }"
              :space-between="50"
              :effect="'creative'"
              :modules="modules"
              :creativeEffect="{
                prev: {
                  shadow: true,
                  translate: [0, 0, -400],
                },
                next: {
                  translate: ['100%', 0, 0],
                },
              }"
          >
            <swiper-slide>
              <h4>FFriday</h4>
              <img class="img-fluid img-swiper" src="/img/software/friday-dashboard.jpg">
            </swiper-slide>
            <swiper-slide>
              <h4>AmicoPOS</h4>
              <img class="img-fluid img-swiper" src="/img/software/amicopos.png">
            </swiper-slide>
            <swiper-slide>
              <h4>WooConnector</h4>
              <img class="img-fluid img-swiper" src="/img/software/wc1.png">
            </swiper-slide>
            <swiper-slide>
              <h4>Distinta base</h4>
              <img class="img-fluid img-swiper" src="/img/software/db1.png">
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </section>-->
  <!--<section class="wrapper bg-black">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <h4 class="text-grey mb-4">La nostra mission</h4>
          <TextScroller :class="'home2'" :text="'However, for brands, AI often feels distant and confined to expert developers, especially in eCommerce where reliance on off-the-shelf SAAS products can lead to outdated technology and a significant gap between innovation and accessibility. We have closed that gap.'"/>
        </div>
      </div>
    </div>
  </section>-->
  <section class="wrapper bg-white">
    <div class="container">
      <div class="row mt-5 mb-5">
        <div class="col-lg-12">
          <h1 class="title-section" data-aos="fade-left">
            Find out how<br>
            we bring to success<br>
            our customers</h1>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-5">
          <h1 class="title-data" data-aos="fade-right">9 - 20%</h1>
        </div>
        <div class="col-lg-7">
          <h2 class="title" data-aos="fade-left">Revenue lift</h2>
          <p class="text-paragraph">
            Whether it’s improving conversion rates, increasing average order value (AOV), or optimizing margins,
            your brands own AI system can effectively target it and address it.
          </p>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-5">
          <h1 class="title-data" data-aos="fade-right">1 - 2 Weeks</h1>
        </div>
        <div class="col-lg-7">
          <h2 class="title" data-aos="fade-left">Max integration time</h2>
          <p class="text-paragraph">
            This is our commitment. Our products are designed for a rapid start, providing you a comprehensive set of tools,
            including Data Set Management, an eComm-ready Model Library, and Experience Deployment.
            Each product is a self-contained toolkit for building and generating fast results with your AI.
          </p>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-5">
          <h1 class="title-data" data-aos="fade-right">0 - 60 min</h1>
        </div>
        <div class="col-lg-7">
          <h2 class="title" data-aos="fade-left">Max compose time</h2>
          <p class="text-paragraph">
            We are composing your AI system to allow you to be in control. This is your product, your design and your AI.
            We build it in the way you want, to target your business goals.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Navigation, EffectCreative } from 'swiper/modules';
import BoxParticles from "@/components/BoxParticles.vue";
import TextScroller from "@/components/TextScroller.vue";
import 'swiper/css';
import 'swiper/css/effect-creative';

export default {
  /* eslint-disable */
  name: 'Home',
  mounted() {
    //let el = document.getElementById('sticky');
    //let nav = document.getElementById('navbar')
    /*
    window.onscroll = function() {
      if (window.pageYOffset + 130 > el.offsetTop) {
        el.classList.add("position-sticky");
      } else {
        el.classList.remove("position-sticky");
      }
    }
     */
  },
  components: {
    Swiper,
    SwiperSlide,
    TextScroller,
    BoxParticles
  },
  data () {
    return {
      autoplay: {
        delay: 1500,
        disableOnInteraction: false
      },
      breakpoints: {
        '@0.30': {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        '@0.75': {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        '@1.00': {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        '@1.50': {
          slidesPerView: 6,
          spaceBetween: 50,
        },
      }
    }
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules : [Autoplay, Navigation, EffectCreative]
    };
  },
  methods : {

  }
}
</script>
<style scoped>

</style>